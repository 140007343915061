import "../../../scss/pages/home.scss";
import Slider from "../components/slider";
import "../common/review-swiper";

import Swiper from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const bannerSwiper = new Swiper(".home-banner-swiper", {
    modules: [Pagination],
    spaceBetween: 30,
    pagination: {
        el: ".home-banner-swiper-pagination",
    },
});

const trustedCompaniesSlider = new Slider(
    document.querySelector(".trusted-companies"),
    document.querySelectorAll(".trusted-companies .slide"),
    35,
    false
);

const supportSwiper = new Swiper(".support-swiper", {
    spaceBetween: 50,
    modules: [Pagination],
    pagination: {
        el: ".support-swiper-pagination",
    },
});
