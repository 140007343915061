export default class Slider {
    #slider;
    #sliderContents;
    constructor(
        slider,
        sliderContents,
        durationInSecond,
        direction = true,
        delay = 0
    ) {
        this.initialize(
            slider,
            sliderContents,
            durationInSecond,
            direction,
            delay
        );
    }
    initialize(slider, sliderContents, durationInSecond, direction, delay) {
        this.slider = slider;
        this.sliderContents = Array.from(sliderContents);
        this.duplicateContent();
        if (direction) {
            this.slider.style.transform = "translate(-50%)";
        }
        this.slider.style.animation = `${
            direction ? "slideRight" : "slideLeft"
        } ${durationInSecond}s linear infinite ${delay}s`;
    }

    duplicateContent() {
        for (let i = 0; i < this.sliderContents.length; i++) {
            let duplicated = this.sliderContents[i].cloneNode(true);
            this.slider.appendChild(duplicated);
        }
    }
}
